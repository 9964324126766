import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import { SurveyTemplate } from "app-types/template";
import PlainHeader from "components/ui/base/PlainHeader";
import { SURVEY_TEMPLATES_PATH } from "constants/paths";
import Customers from "components/ui/extended/Customers";
import RegisterForCustomerSatisfaction from "components/ui/extended/RegisterForCustomerSatisfaction";
import TestimonialWithImageDaniele from "components/ui/extended/TestimonialWithImageDaniele";
import SurveyTemplates from "components/ui/extended/SurveysTemplates";
import BlogPostsBox from "components/ui/base/BlogPostsBox";

interface SurveyTemplatesPageProps {
  data: {
    allSurveysTemplates: {
      nodes: SurveyTemplate[];
    };
  };
  location: Location;
}

export default ({ data, location }: SurveyTemplatesPageProps) => {
  const { t } = useTranslation();

  const surveyTemplates = data.allSurveysTemplates.nodes;

  const totalTemplates = surveyTemplates.length;

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.survey-templates.seo.title")}
        description={t("pages.survey-templates.seo.description")}
        image={{
          relativePath: "meta/success-stories-from-customerly-customers.jpg",
          alt: "Templates for Email Marketing from Customerly",
        }}
      />
      <PlainHeader
        title={t("pages.survey-templates.title")}
        description={t("pages.survey-templates.description", {
          count: totalTemplates,
        })}
        maxWidth={900}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Templates",
            path: "/templates/",
          },
          {
            name: "Survey Templates",
            path: `${SURVEY_TEMPLATES_PATH}/`,
          },
        ]}
      />

      <SurveyTemplates templates={surveyTemplates} />

      <TestimonialWithImageDaniele />

      <RegisterForCustomerSatisfaction />

      <Customers />

      <BlogPostsBox
        category={"surveys"}
        title={"Surveys Articles"}
        maxPosts={3}
        postsPerRow={3}
        currentPostSlug={"/"}
      />
    </GlobalWrapper>
  );
};
export const templatesPageQuery = graphql`
  query {
    allSurveysTemplates {
      nodes {
        background
        description
        emoji
        survey_template_id
        name
        questions {
          title
          subtitle
          choices {
            value
          }
        }
        survey {
          name
          thankyou_text
        }
        author {
          account_id
          bio
          id
          public_name
          username
          website
        }
      }
    }
  }
`;

import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { SurveyTemplate } from "app-types/template";
import TemplateGrid from "components/ui/base/TemplateGrid";
import TemplateCard from "components/ui/base/TemplateGrid/TemplateCard";
import { getSurveyTemplatePagePath } from "utils/path";
import { SURVEY_TEMPLATES_PATH } from "constants/paths";

interface SurveyTemplatesProps {
  templates: SurveyTemplate[];
  showMore?: boolean;
}

const SurveyTemplates: FunctionComponent<SurveyTemplatesProps> = ({
  templates,
  showMore,
}) => {
  const { t } = useTranslation();

  return (
    <TemplateGrid
      id={"survey-templates"}
      title={t("components.ui.extended.SurveyTemplates.title")}
      description={t("components.ui.extended.SurveyTemplates.description", {
        count: templates.length,
      })}
      data={templates}
      component={(surveyTemplate) => (
        <TemplateCard
          id={`survey-template-${surveyTemplate.survey_template_id}`}
          path={getSurveyTemplatePagePath(
            surveyTemplate.survey_template_id,
            surveyTemplate.name,
            surveyTemplate.author.username
          )}
          background={surveyTemplate.background}
          text={surveyTemplate.questions[0].title}
          title={surveyTemplate.name}
          description={surveyTemplate.description}
          emoji={surveyTemplate.emoji}
        />
      )}
      showMore={
        showMore
          ? {
              title: t("components.ui.extended.SurveyTemplates.showMore.title"),
              description: t(
                "components.ui.extended.SurveyTemplates.showMore.description"
              ),
              button: t(
                "components.ui.extended.SurveyTemplates.showMore.button"
              ),
              path: `${SURVEY_TEMPLATES_PATH}/`,
            }
          : undefined
      }
    />
  );
};

export default SurveyTemplates;
